/* eslint-disable no-console */

import { register } from 'register-service-worker'

register('./service-worker.js', {
  ready () {
    console.log(':)')
  },
  registered () {
  },
  cached () {
  },
  updatefound () {
  },
  updated () {
  },
  offline () {
  },
  error (error) {
    console.log(error)
  }
})
