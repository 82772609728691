<template>
  <div id="app" class="home">
    <header>
      <img width="100" height="100" src="@/assets/spotify.png" alt="spotify">
      <h1>elvinSweetHome 2023</h1>
    </header>
    <section class="cardMembers">
      <h2>Current Month: {{ getCurrentMonth() }}</h2>
      <table>
        <tr>
          <th>Member</th>
          <th :class="{'currentMonth': month === 0}">JAN</th>
          <th :class="{'currentMonth': month === 1}">FEB</th>
          <th :class="{'currentMonth': month === 2}">MAR</th>
          <th :class="{'currentMonth': month === 3}">APR</th>
          <th :class="{'currentMonth': month === 4}">MAY</th>
          <th :class="{'currentMonth': month === 5}">JUN</th>
          <th :class="{'currentMonth': month === 6}">JUL</th>
          <th :class="{'currentMonth': month === 7}">AUG</th>
          <th :class="{'currentMonth': month === 8}">SEP</th>
          <th :class="{'currentMonth': month === 9}">OCT</th>
          <th :class="{'currentMonth': month === 10}">NOV</th>
          <th :class="{'currentMonth': month === 11}">DEC</th>
        </tr>
        <tr>
          <td>hari***</td>
          <td :class="{'currentMonth': month === 0}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 1}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 2}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 3}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 4}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 5}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 6}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 7}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 8}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 9}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 10}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 11}" class="paid">6 JAN 23</td>
        </tr>
        <tr>
          <td>napa***</td>
          <td :class="{'currentMonth': month === 0}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 1}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 2}" class="paid">6 JAN 23</td>
          <td :class="{'currentMonth': month === 3}" class="paid">31 MAR 23</td>
          <td :class="{'currentMonth': month === 4}" class="paid">5 JUN 23</td>
          <td :class="{'currentMonth': month === 5}" class="paid">5 JUN 23</td>
          <td :class="{'currentMonth': month === 6}" class="paid">2 JUL 23</td>
          <td :class="{'currentMonth': month === 7}" class=""></td>
          <td :class="{'currentMonth': month === 8}" class=""></td>
          <td :class="{'currentMonth': month === 9}" class=""></td>
          <td :class="{'currentMonth': month === 10}" class=""></td>
          <td :class="{'currentMonth': month === 11}" class=""></td>
        </tr>
        <tr>
          <td>
            <span class="mvp">
              <img width="16" src="@/assets/mvp.png" alt="mvp">
              MVP
            </span>
            pont***
          </td>
          <td :class="{'currentMonth': month === 0}" class="paid">4 JAN 22</td>
          <td :class="{'currentMonth': month === 1}" class="paid">4 JAN 22</td>
          <td :class="{'currentMonth': month === 2}" class="paid">4 JAN 22</td>
          <td :class="{'currentMonth': month === 3}" class="paid">4 JAN 22</td>
          <td :class="{'currentMonth': month === 4}" class="paid">4 JAN 22</td>
          <td :class="{'currentMonth': month === 5}" class="paid">4 JAN 22</td>
          <td :class="{'currentMonth': month === 6}" class="paid">4 JAN 22</td>
          <td :class="{'currentMonth': month === 7}" class="paid">4 JAN 22</td>
          <td :class="{'currentMonth': month === 8}" class="paid">4 JAN 22</td>
          <td :class="{'currentMonth': month === 9}" class="paid">4 JAN 22</td>
          <td :class="{'currentMonth': month === 10}" class="paid">4 JAN 22</td>
          <td :class="{'currentMonth': month === 11}" class="paid">4 JAN 22</td>
        </tr>
        <tr>
          <td>
            <span class="mvp">
              <img width="16" src="@/assets/mvp.png" alt="mvp">
              MVP
            </span>
            zero***
          </td>
          <td :class="{'currentMonth': month === 0}" class="paid">4 JAN 23</td>
          <td :class="{'currentMonth': month === 1}" class="paid">4 JAN 23</td>
          <td :class="{'currentMonth': month === 2}" class="paid">4 JAN 23</td>
          <td :class="{'currentMonth': month === 3}" class="paid">4 JAN 23</td>
          <td :class="{'currentMonth': month === 4}" class="paid">4 JAN 23</td>
          <td :class="{'currentMonth': month === 5}" class="paid">4 JAN 23</td>
          <td :class="{'currentMonth': month === 6}" class="paid">4 JAN 23</td>
          <td :class="{'currentMonth': month === 7}" class="paid">4 JAN 23</td>
          <td :class="{'currentMonth': month === 8}" class="paid">4 JAN 23</td>
          <td :class="{'currentMonth': month === 9}" class="paid">4 JAN 23</td>
          <td :class="{'currentMonth': month === 10}" class="paid">4 JAN 23</td>
          <td :class="{'currentMonth': month === 11}" class="paid">4 JAN 23</td>
        </tr>
        <tr>
          <td>supa***</td>
          <td :class="{'currentMonth': month === 0}" class="unpaid"></td>
          <td :class="{'currentMonth': month === 1}" class="unpaid"></td>
          <td :class="{'currentMonth': month === 2}" class="unpaid"></td>
          <td :class="{'currentMonth': month === 3}" class="unpaid"></td>
          <td :class="{'currentMonth': month === 4}" class="unpaid"></td>
          <td :class="{'currentMonth': month === 5}" class="unpaid"></td>
          <td :class="{'currentMonth': month === 6}" class="unpaid"></td>
          <td :class="{'currentMonth': month === 7}" class=""></td>
          <td :class="{'currentMonth': month === 8}" class=""></td>
          <td :class="{'currentMonth': month === 9}" class=""></td>
          <td :class="{'currentMonth': month === 10}" class=""></td>
          <td :class="{'currentMonth': month === 11}" class=""></td>
        </tr>
      </table>
      <article class="payments">
        <h3>36.5฿ Each Month (Any Day)</h3>
        <h4>K-BANK Transfer: 1932749147</h4>
        <h4>PromptPay Number: 0931159127</h4>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      month: 0,
      year: 2022,
      months: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    };
  },
  created() {
    const date = new Date();
    this.month = date.getMonth();
    this.year = date.getFullYear();
  },
  methods: {
    getCurrentMonth() {
      return `${this.months[this.month]} ${this.year}`;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@500&display=swap');

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#app {
  font-family: 'IBM Plex Sans Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $spotify-black;
}

.mvp {
  background: $spotify-green;
  border-radius: 3px;
  color: #FFF;
  margin-right: 4px;
  padding-left: 2px;

  img {
    position: relative;
    top: 2px;
  }

  @media screen and (max-width: 1168px) {
    padding-right: 2px;
  }
}

.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background: $spotify-green;
  header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    img {
      width: 100px;
      margin-right: 20px;
    }
    h1 {
      color: $spotify-white;
    }
  }
  .cardMembers {
    width: 90vw;
    height: auto;
    border-radius: 20px;
    background: $spotify-white;
    padding: 40px;
    text-align: center;
    table {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      border: none;
      width: 100%;
      tr {
        th {
          background: $spotify-black;
          color: $spotify-white;
        }
        td {
          background: $brown;
        }
        td, th {
          padding: 10px 2px;
          text-align: center;
          min-width: 72px;
          &.unpaid {
            background: $red;
          }
          &.currentMonth {
            background: $yellow;
            color: $spotify-black;
          }
          &.paid {
            color: #FFF;
            background: $spotify-green;
          }
          &:first-child {
            background: $spotify-black;
            color: $spotify-white;
          }
        }
      }
    }
    .payments {
      h4 {
        margin: 0 0 4px;
      }
    }
  }
}
@media screen and (max-width: 1120px) {
  .home {
    .cardMembers {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
@media screen and (max-width: 540px) {
  .home {
    justify-content: flex-start;
    header {
      margin-top: 20px;
      img {
        width: 56px;
        height: 56px;
      }
    }
    .cardMembers {
      width: 100%;
      border-radius: 0;
      padding: 22px 0 40px 20px;
      text-align: center;
      table {
        width: auto;
        overflow-x: scroll;
        display: block;
        margin-right: 20px;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .home {
    header {
      img {
        width: 48px;
        height: 48px;
        margin-right: 10px;
      }
      h1 {
        font-size: 1.5em;
      }
    }
  }
}
</style>
